

































import { SfSteps } from '@storefront-ui/vue';
import {
  computed,
  defineComponent,
  ref,
  useRoute,
  useRouter,
  useContext,
  onMounted,
} from '@nuxtjs/composition-api';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import useCart from '~/modules/checkout/composables/useCart';
import CartPreview from '~/modules/checkout/components/CartPreview.vue';

export default defineComponent({
  name: 'CheckoutPage',
  components: {
    SfSteps,
    CartPreview,
  },
  setup() {
    const route = useRoute();
    const { app } = useContext();
    const { path } = route.value;
    const router = useRouter();
    const { cart, load } = useCart();
    const products = computed(() => cartGetters.getItems(cart.value));
    const currentStep = computed(() => path.split('/').pop());

    const STEPS = ref([
      {
        title: 'User Account',
        url: 'user-account',
      },
      {
        title: 'Shipping',
        url: 'shipping',
      },
      {
        title: 'Billing',
        url: 'billing',
      },
      {
        title: 'Payment',
        url: 'payment',
      },
    ]);

    const currentStepIndex = computed(() => STEPS.value.findIndex((step) => step.url === currentStep.value));
    const isThankYou = computed(() => currentStep.value === 'thank-you');

    const handleStepClick = async (stepIndex: number) => {
      if (stepIndex <= currentStepIndex.value) {
        const { url } = STEPS.value[stepIndex];
        await router.push(`${app.localePath(`/checkout/${url}`)}`);
      }
    };

    onMounted(async () => {
      await load();
      if (products.value.length === 0 && currentStep.value !== 'thank-you') {
        await router.push(app.localePath('/'));
      }
    });

    return {
      handleStepClick,
      STEPS,
      currentStepIndex,
      isThankYou,
      currentStep,
    };
  },
});
